import React, { useState } from "react"
import BlockText from "../components/block-text/block-text"
import Hero from "../components/hero/hero"
import Layout from "../components/layout/layout"
import Section from "../components/section/section"
import Chart from "../components/chart/chart"
import CardFigure from "../components/card-figure/card-figure"
import About from "../components/about/about"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import dataJSON from "../data.js";
import Header from "../components/header/header"

const currentLang = 'it';

// markup
const IndexPage = () => {
  const data = dataJSON[currentLang];

  const services = function () {
    return (
      <div className="row">
        {data.services.map((service) => (
          <div className="col-lg-4 col-md-6" key={service.id}>
            <BlockText {...service} />
          </div>
        ))}
      </div>
    );
  }();

  const approaches = function () {
    return (
      <div className="row justify-content-md-center">
        {data.approaches.map((chart) => (
          <div className="col-sm-12 col-md-4" key={chart.id}>
            <Chart {...chart} />
          </div>
        ))}
      </div>
    );
  }();

  const cardsAbout = function () {
    return (
      <div className="row">
        {data.cardsAbout.map((card) => (
          <div className="col-6 col-lg-3" key={card.id}>
            <CardFigure {...card} />
          </div>
        ))}
      </div>
    )
  }();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleIsMenuOpen = (isMenuOpen) => {
    setMenuOpen(isMenuOpen);
  }

  return (
    <React.Fragment>
      <Header handleIsMenuOpen={handleIsMenuOpen} data={dataJSON.navigation[currentLang]} homeLink='/it' />
      <Layout menuOpen={menuOpen}>
        <Helmet
          htmlAttributes={{
            lang: currentLang,
          }}
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.page.title}</title>
          <meta name="description" content={data.page.description} />
          <meta name="keywords" content={data.page.keywords} />
          <meta name="robots" content="index,follow" />
          <link rel="canonical" href="https://avoc.ch/" />
          <meta property="og:title" content={data.page.title} />
          <meta property="og:description" content={data.page.description} />
          <meta property="og:locale" content={process.env.GATSBY_APP_LANG} />
          <meta property="og:locale:alternate" content="en_GB" />
          <meta property="og:locale:alternate" content="it_CH" />
          <meta property="og:locale:alternate" content="fr_CH" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://avoc.ch/" />
          <meta property="og:image" content="https://avoc.ch/og_image.png" />
          <script type="application/ld+json">
            {
              `
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Yverdon-les-Bains",
              "addressRegion": "VD",
              "postalCode": "1400",
              "streetAddress": "Chemin de Sous-Bois"
            },
            "location": "Yverdon-les-Bains",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "18"
            },
            "name": "${data.page.title}",
            "openingHours": [
              "Mo-r 09:00-18:00"
            ],
            "email": "avoc@avoc.ch",
            "url": "http://www.avoc.ch"
            `
            }
          </script>
          <link href="https://fonts.googleapis.com/css?family=Raleway:300,400,600|Source+Sans+Pro:400,600,300|Roboto:300&amp;display=swap" media="print" onload="this.onload=null;this.removeAttribute('media');" rel="stylesheet" />
        </Helmet>
        <Hero data={dataJSON.hero[currentLang]} />
        <Section id="services" {...data.servicesTop}>
          {services}
        </Section>
        <Section id="assistive-text">
          <p>{data.assistiveText}</p>
        </Section>
        <Section id="approche" className="--dark" {...data.approachTop}>
          {approaches}
        </Section>
        <Section id="a-propos" noHeader="true">
          <About data={dataJSON.about[currentLang]} />
          {cardsAbout}
        </Section>
        <Footer data={dataJSON.footer[currentLang]} />
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
